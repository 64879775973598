/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react";
import RootElement from "./src/RootElement";
import axios, { AxiosError } from 'axios';
import "./src/history";

axios.interceptors.response.use(response => response, function (error) {
  if (error instanceof AxiosError) {
    try {
      error.message = error.response!.data.detail;
      error.name = "Backend error";
    } catch (e) { }
  }
  return Promise.reject(error);
});

export const wrapRootElement = ({ element }: { element: any }) => {
  return <RootElement>{element}</RootElement>;
};
