import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { FC, ReactElement } from "react";
import { SidebarContextProvider } from "./components/menu/SidebarContext";
import { NotifierContextProvider } from "./components/notifications/reducer";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
    },
  },
});

const RootElement: FC<{ children: ReactElement }> = ({ children }) => {
  return (
    <NotifierContextProvider>
      <SidebarContextProvider>
        <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
      </SidebarContextProvider>
    </NotifierContextProvider>
  );
};

export default RootElement;
