import { FC, ReactNode } from 'react';
import { Header, Icon, Segment, Sidebar } from "semantic-ui-react";
import styled from "styled-components";
import { useSidebarSetter } from "./SidebarContext";

export interface SidebarBaseProps {
  header?: string;
  children?: ReactNode;
  loading?: boolean;
  error?: string | boolean;
  buttons?: ReactNode;
}
export interface SidebarBaseSuccessProps extends SidebarBaseProps {
  header: string;
  error?: "" | false;
}
export interface SidebarBaseErrorProps extends SidebarBaseProps {
  error: Exclude<string, ""> | true;
}

export const SidebarBase: FC<SidebarBaseSuccessProps | SidebarBaseErrorProps> = (props) => {
  return <StyledSidebar direction="right" visible>
    <Header as="h4" attached="top">
      { props.header || "Wystąpił błąd" }
      <Icon name="close" size="small" tabIndex="0" onClick={useSidebarSetter(null)} />
    </Header>
    { props.error ? <Segment attached disabled>
      { typeof props.error === "boolean" ? "Coś poszło nie tak" : props.error }
    </Segment> : <>
      <Segment attached loading={props.loading}>{ props.children }</Segment>
      { props.buttons ? <ButtonsSegment attached> { props.buttons } </ButtonsSegment> : null }
    </> }
  </StyledSidebar>;
};

const ButtonsSegment = styled(Segment)`
  display: flex;
  flex-direction: column;

  > * {
    flex-grow: 1;
  }
`;

const StyledSidebar = styled(Sidebar)`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 0 1px;

  > * {
    border-radius: 0 !important;
    border-right: none !important;
    border-top: none !important;
  }

  > .header + .segment {
    flex-grow: 1;
    overflow-y: auto;
  }

  > :last-child {
    border-bottom: none !important;
  }

  > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > .icon {
      cursor: pointer;
      flex-grow: 0;
      margin-right: 0 !important;
      opacity: 0.4 !important;
      transition: opacity 0.2s ease-in-out !important;
      font-size: .9em !important;

      &:hover, &:focus {
        opacity: 1 !important;
      }
    }
  }
`;
