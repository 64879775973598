import { FC, ReactElement, ReactNode, createContext, useCallback, useMemo, useReducer } from 'react';
import { INotification, INotifierContext } from './types';
import { MessageBox } from '../misc/confirmation';

enum NotificationType {
  ADD_TOAST,
  DISMISS_TOAST,
  DISMISS_ALL_TOASTS,
  SET_ALERT,
  DISMISS_ALERT,
}

type TState = {
  alertMessage: {message: ReactNode, isError: boolean} | null;
  notifications: INotification[];
}

type TAction =
  | { type: NotificationType.ADD_TOAST; arg: INotification }
  | { type: NotificationType.DISMISS_TOAST; arg: number }
  | { type: NotificationType.DISMISS_ALL_TOASTS; arg?: undefined }
  | { type: NotificationType.SET_ALERT; arg: { message: ReactNode, isError: boolean } }
  | { type: NotificationType.DISMISS_ALERT; arg?: undefined };

function reducer({ notifications, alertMessage }: TState, { type, arg }: TAction): TState {
  switch (type) {
    case NotificationType.ADD_TOAST:
      return { alertMessage, notifications: [...notifications, arg] };
    case NotificationType.DISMISS_TOAST:
      return { alertMessage, notifications: notifications.filter(item => item.id !== arg) };
    case NotificationType.DISMISS_ALL_TOASTS:
      return { alertMessage, notifications: [] };
    case NotificationType.SET_ALERT:
      return { notifications: notifications, alertMessage: arg };
    case NotificationType.DISMISS_ALERT:
      return { notifications: notifications, alertMessage: null };
    default:
      return { alertMessage, notifications: notifications };
  }
}

export const NotifierContext = createContext<INotifierContext>({
  notifications: [],
  notify: () => void 0,
  dismissToast: () => void 0,
  dismissAllToasts: () => void 0,
  alert: () => void 0,
});

let id = 0;

export const NotifierContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [{ notifications, alertMessage }, dispatch] = useReducer(reducer, {
    notifications: [],
    alertMessage: null,
  });

  const value: INotifierContext = useMemo(() => ({
    notifications,
    notify(children: ReactElement) {
      dispatch({ type: NotificationType.ADD_TOAST, arg: { id: ++id, children } });
    },
    dismissToast(id: number) {
      dispatch({ type: NotificationType.DISMISS_TOAST, arg: id });
    },
    dismissAllToasts() {
      dispatch({ type: NotificationType.DISMISS_ALL_TOASTS });
    },
    alert(message: ReactNode, isError: boolean) {
      dispatch({ type: NotificationType.SET_ALERT, arg: { message, isError } });
    },
  }), [notifications]);

  const onClose = useCallback(() => dispatch({ type: NotificationType.DISMISS_ALERT }), [dispatch]);

  return <NotifierContext.Provider value={value}>
    { children }
    { alertMessage && <MessageBox
      header={alertMessage?.isError ? "Błąd" : undefined}
      negative={alertMessage?.isError}
      onClose={onClose}
      open={!!alertMessage}>{ alertMessage?.message }</MessageBox> }
  </NotifierContext.Provider>;
};
