import { FC, ReactNode, createContext, useCallback, useContext, useMemo, useState } from 'react';
import { SidebarBase } from "./SidebarBase";
import { SidebarProps, TSidebar } from "./SidebarTypes";

export type TSidebarContext = [TSidebar | null, (sidebar: TSidebar | null) => void];
export const SidebarContext = createContext<TSidebarContext>([ null, () => void 0 ]);

export const SidebarContextProvider: FC<{ children: ReactNode }> = (props) => {
  const [sidebarState, setSidebarState] = useState<TSidebar | null>(null);
  const sidebarStateContext: TSidebarContext = useMemo(
    () => [sidebarState, (sidebar: TSidebar | null) => setSidebarState(() => sidebar)],
    [sidebarState, setSidebarState]
  );

  return <SidebarContext.Provider value={sidebarStateContext}>
    { props.children }
  </SidebarContext.Provider>;
};

export const useSidebarSetter = (sidebar: TSidebar | null) => {
  const setSidebar = useContext(SidebarContext)[1];
  return useCallback(() => setSidebar(sidebar), [sidebar, setSidebar]);
};

export const RenderSidebar: FC<SidebarProps> = (props) => {
  const [Sidebar, setSidebar] = useContext(SidebarContext);
  try {
    if (!Sidebar) return null;
    const expectedProps = Object.keys(Sidebar.propTypes);
    const actualProps = Object.keys(props);
    if (!expectedProps.every((prop) => actualProps.includes(prop))) {
      console.warn("Sidebar props do not match:", expectedProps, actualProps);
      setSidebar(null);
      return null;
    }
    if (expectedProps.some((prop) => prop == null)) {
      return <SidebarBase header="\xa0" loading />;
    } else {
      return <Sidebar {...props} />;
    }
  } catch (e) {
    const msg = (typeof e === "object" && e && "message" in e) ? e.message : e;
    const error = (typeof msg !== "string") || msg || true;
    return <SidebarBase error={error} {...props} />;
  }
};
