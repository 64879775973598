import { FC, ReactNode, useState } from "react";
import { Button, ButtonProps, Icon, Modal, ModalProps, SemanticICONS, StrictButtonProps } from "semantic-ui-react";
import { styled } from "styled-components";

interface ConfirmProps {
  open: boolean;
  cancelButton?: ReactNode;
  confirmButton?: ReactNode;
  content?: ReactNode;
  header?: ReactNode;
  negative?: boolean;
  onConfirm: (event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps) => void;
  onCancel?: (event: React.MouseEvent<HTMLElement>, data: ButtonProps | ModalProps) => void;
  children?: ReactNode;
  size?: ModalProps["size"];
}
export const Confirm: FC<ConfirmProps> = ({
  header, confirmButton, cancelButton, onConfirm, onCancel, open, negative, children, content, size
}) => {
  if (!content) content = children || "Czy jesteś pewien?";

  return <Modal open={open} size={size || "tiny"} onClose={onCancel}>  
    { header && <Modal.Header>{ header }</Modal.Header> }
    { negative ? <FlexContent>
      <Icon name='warning sign' color='red' size='big' />
      <StyledContent>{ content }</StyledContent>
    </FlexContent> : <Modal.Content as={StyledContent}>{ content }</Modal.Content> }
    <Modal.Actions>
      <ActionsContainer>{
        negative ? <>
          <Button onClick={onConfirm} negative>{ confirmButton || "Potwierdź" }</Button>
          <Button onClick={onCancel} positive>{ cancelButton || "Anuluj" }</Button>
        </> : <>
          <Button onClick={onCancel}>{ cancelButton || "Anuluj" }</Button>
          <Button onClick={onConfirm} primary>{ confirmButton || "OK" }</Button>
        </>
      }</ActionsContainer>
    </Modal.Actions>
  </Modal>;
};

interface MessageBoxProps {
  open: boolean;
  button?: ReactNode;
  header?: ReactNode;
  negative?: boolean;
  onClose?: (event: React.MouseEvent<HTMLElement>, data: ButtonProps | ModalProps) => void;
  children?: ReactNode;
  icon?: SemanticICONS;
  size?: ModalProps["size"];
}
export const MessageBox: FC<MessageBoxProps> = ({
  open, button, header, negative, onClose, children, icon, size
}) => {
  return <Modal open={open} size={size || "tiny"} onClose={onClose}>  
    { header && <Modal.Header>{ header }</Modal.Header> }
    { (negative || icon) ? <FlexContent>
      <Icon name={icon || 'warning sign'} color={negative ? 'red' : undefined} size='big' />
      <StyledContent>{ children }</StyledContent>
    </FlexContent> : <Modal.Content as={StyledContent}>{ children }</Modal.Content> }
    <Modal.Actions>
      <ActionsContainer>
        <Button onClick={onClose} negative={negative} positive={!negative}>{ button || "OK" }</Button>
      </ActionsContainer>
    </Modal.Actions>
  </Modal>; 
};

type ConfirmationButtonProps = StrictButtonProps & Omit<ConfirmProps, "header" | "content" | "open"> & {
  confirmationHeader?: ReactNode;
  confirmationText?: ReactNode;
};
export const ConfirmationButton: FC<ConfirmationButtonProps> = ({
  confirmationHeader, confirmationText, cancelButton, confirmButton,
  onClick, onCancel, onConfirm, ...props
}) => {
  const [open, setOpen] = useState(false);

  const onCancelEx = (event: React.MouseEvent<HTMLElement>, data: ButtonProps | ModalProps) => {
    setOpen(false);
    if (onCancel) onCancel(event, data);
  };

  const onConfirmEx = (event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps) => {
    setOpen(false);
    onConfirm(event, data);
  };

  return <>
    <Button {...props} onClick={() => setOpen(true)} />
    <Confirm
      negative={props.negative}
      header={confirmationHeader}
      content={confirmationText}
      confirmButton={confirmButton}
      cancelButton={cancelButton}
      open={open}
      onCancel={onCancelEx}
      onConfirm={onConfirmEx} />
  </>;
};

const ActionsContainer = styled.div.attrs({ className: "actions" })`
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  width: max-content;
  margin-left: auto;
`;

const FlexContent = styled(Modal.Content)`
  display: flex !important;
  flex-direction: row;
  grid-gap: 1.35rem;
`;

const StyledContent = styled.div`
  q { font-style: italic; }
`;
